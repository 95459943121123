.main {
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 100vh;
  transition: $sidebar-transition;
  background: $body-bg;
  flex-direction: column;
  overflow: hidden;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

body{font-family: "Lato", Arial, sans-serif;font-weight: normal;background: #f8f9fd;color:gray;}
.dx-logo{width:172px;float:right;}
.datatable{background-color:white;}
.btn-toggle{border:1px solid lightgray;}